import React, { Fragment } from 'react';
import { string, object } from 'prop-types';
import { startsWith, memoize, split, map, get } from 'lodash';

const getWords = memoize(sentence => {
	const words = split(sentence, /\s/);
	return map(words, word => ({
		word,
		isUrl: startsWith(word, 'http://') || startsWith(word, 'https://'),
	}));
});

const doNotExpandRow = e => e.stopPropagation();

const Link = ({ value, dependentValues }) => {
	const dependentValue = get(dependentValues, 'value');
	const words = getWords(dependentValue || value);
	return (
		<div title={value}>
			{map(words, ({ word, isUrl }, index) => (
				<Fragment key={index}>
					{isUrl ? (
						<a
							onClick={doNotExpandRow}
							className="display--b btn btn--link flex--left type--ellipsis"
							href={word}
							target="_blank"
						>
							{word}
						</a>
					) : (
						word
					)}
					{index !== words.length && ' '}
				</Fragment>
			))}
		</div>
	);
};

Link.propTypes = {
	value: string,
	dependentValues: object,
};

export default Link;
